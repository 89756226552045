import React from "react";
import LawfareSolicitorsWebP from "../../images/AdsImages/webp/Lawfaresolictitors.webp";

import AkramSheikhLahore from "../../images/AdsImages/AkramSheikhLahore.png";
import AkramSheikhLahoreWebP from "../../images/AdsImages/webp/AkramSheikhLahore.webp";
import AkramSheikhLahoreAVIF from "../../images/AdsImages/avif/AkramSheikhLahore.avif";

import AkramSheikhIslamabadAVIF from "../../images/AdsImages/avif/AkramSheikhIslamabad.avif";
import AkramSheikhIslamabadWebP from "../../images/AdsImages/webp/AkramSheikhIslamabad.webp";
import AkramSheikhIslamabad from "../../images/AdsImages/AkramSheikhIslamabad.png";



import "./Sidebar.css";

function LawSidebar() {
  return (
    <div className="sidebar">
      <div className="advertisement">
        <picture>
          <source srcSet={AkramSheikhLahoreAVIF} type="image/avif" />
          <source srcSet={AkramSheikhLahoreWebP} type="image/webp" />
          <img
            className="Ads_ImageStyle"
            src={AkramSheikhLahore}
            alt="Akram Sheikh Lahore"
            loading="lazy"
          />
        </picture>
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={AkramSheikhIslamabadWebP}
          alt="Akram Sheikh Islamabad"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={LawfareSolicitorsWebP}
          alt="Lawfare solicitors Company Promotion"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default LawSidebar;
