import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

//not used
import LondonRoundUp from "./pages/English/MainPages/LondonRoundupEnglish.jsx";
import AboutPage from "./pages/English/SubPages/AboutPage.jsx";
import HealthPage from "./pages/English/MainPages/Healthpage.jsx";

//main pages
import ContactPage from "./pages/English/SubPages/ContactPage.jsx";
import HomePageEnglish from "./pages/English/MainPages/HomePageEnglish.jsx";
import VideoPage from "./pages/English/SubPages/VideosPage.jsx";
import UrduHomePage from "./pages/Urdu/HomeUrduPage.jsx";
import NewsPage from "./pages/English/MainPages/NewsPage.jsx";
import ArtsandliteraturePage from "./pages/English/MainPages/ArtsandLiteraturePage.jsx";
import InterviewPage from "./pages/English/MainPages/InterviewPage.jsx";
import PictureGallery from "./pages/English/MainPages/PictureGallery/PictureGallery.jsx";

//personality pages
import UKPersonalityPage from "./pages/English/MainPages/UKPersonalityPage.jsx";
import PKPersonalityPage from "./pages/English/MainPages/PKPersonalityPage.jsx";
import TechnologyPage from "./pages/English/ServicePages/TechnologyPage.jsx";

//services
import AccountancyPage from "./pages/English/ServicePages/AccountancyPage.jsx";
import EducationPage from "./pages/English/ServicePages/EducationPage.jsx";
import LawPage from "./pages/English/ServicePages/LawPage.jsx";

//showbiz
import ShowbizPage from "./pages/English/MainPages/ShowbizPage.jsx";

//error pages
import ErrorPage from "./pages/English/MainPages/ErrorPage.jsx";

//personality pages
import BasharatAliGill from "./components/Peronality/PKPersonalityPages/Basharat Ali Gill.jsx";
import AkramSheikh  from "./components/Peronality/PKPersonalityPages/Akram Sheikh";

function App() {
  function LowercaseRoute({ path, element }) {
    const lowercasePath = path.toLowerCase();
    return <Route path={lowercasePath} element={element} />;
  }

  return (
    <div>
      <Router>
        <Routes>
          {/* main pages */}
          <Route exact path="/" element={<HomePageEnglish />} />
          <Route path="/*" element={<ErrorPage />} />
          <Route path="/videos" element={<VideoPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/picturegallery" element={<PictureGallery />} />

          <Route
            path="/artsandliterature"
            element={<ArtsandliteraturePage />}
          />
          {/* <Route path="/interviews" element={<InterviewPage />} /> */}

          {/* services */}
          <Route path="/accountancy" element={<AccountancyPage />} />
          <Route path="/education" element={<EducationPage />} />
          <Route path="/Law" element={<LawPage />} />
          <Route path="/technology" element={<TechnologyPage />} />

          {/* not used */}
          {/* <Route path="/londonroundup" element={<LondonRoundUp />} /> */}
          <Route path="/about" element={<AboutPage />} />
          {/* <Route path="/health" element={<HealthPage />} /> */}

          {/* personality pages */}
          <Route path="/ukpersonality" element={<UKPersonalityPage />} />
          <Route path="/pkpersonality" element={<PKPersonalityPage />} />

          {/* urdu pages */}
          {/* <Route path="/urdu" element={<UrduHomePage />} /> */}

          {/* showbiz page */}
          {/* <Route path="/showbiz" element={<ShowbizPage />} /> */}

          {/* Personalities */}
          <Route path="/pkpersonality/BasharatAliGill" element={<BasharatAliGill />} />
          <Route path="/pkpersonality/AkramSheikh" element={<AkramSheikh />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
