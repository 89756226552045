import React from "react";
import "./ClassAds.css";

// Import your images in all three formats: PNG, WebP, and AVIF
import classadslogo from "../../images/logos/Classads.png";
import classadslogoWebP from "../../images/logos/webp/Classads.webp";
import classadslogoAVIF from "../../images/logos/avif/Classads.avif";

import ZiaLaw from "../../images/AdsImages/ZiaLaw.jpeg";
import ZiaLawWebP from "../../images/AdsImages/webp/ZiaLaw.webp";
import ZiaLawAVIF from "../../images/AdsImages/avif/ZiaLaw.avif";

import AAACounsaltants from "../../images/AdsImages/AAAConsultants.jpeg";
import AAACounsaltantsWebP from "../../images/AdsImages/webp/AAAConsultants.webp";
import AAACounsaltantsAVIF from "../../images/AdsImages/avif/AAAConsultants.avif";

import FutureTech from "../../images/AdsImages/FutureTechSystems.jpeg";
import FutureTechWebP from "../../images/AdsImages/webp/FutureTechSystems.webp";
import FutureTechAVIF from "../../images/AdsImages/avif/FutureTechSystems.avif";

import DailyAdl from "../../images/AdsImages/DailyAdl.png";
import DailyAdlWebP from "../../images/AdsImages/webp/DailyAdl.webp";
import DailyAdlAVIF from "../../images/AdsImages/avif/DailyAdl.avif";

import DailyAdlRepresentative from "../../images/AdsImages/DailyAdlHire.jpeg";
import DailyAdlRepresentativeWebP from "../../images/AdsImages/webp/DailyAdlHire.webp";
import DailyAdlRepresentativeAVIF from "../../images/AdsImages/avif/DailyAdlHire.avif";

// import AkramSheikhLahore from "../../images/AdsImages/AkramSheikhLahore.png";
// import AkramSheikhLahoreWebP from "../../images/AdsImages/webp/AkramSheikhLahore.webp";
// import AkramSheikhLahoreAVIF from "../../images/AdsImages/avif/AkramSheikhLahore.avif";

import BalochAutos from "../../images/AdsImages/BalochAutos.png";
import BalochAutosWebP from "../../images/AdsImages/webp/BalochAutos.webp";
import BalochAutosAVIF from "../../images/AdsImages/avif/BalochAutos.avif";

import AlkhidmatFoundationPakistan from "../../images/AdsImages/Alkhidmat Foundation Pakistan.jpeg";

import BusyB from "../../images/AdsImages/Busy B.jpg";

import GhurkiHospital from "../../images/AdsImages/Ghurki Hospital.jpeg";

import CelebratesInternationalWomenDay from "../../images/AdsImages/Celebrates International Women Day .jpeg";

import USCBunyadi from "../../images/AdsImages/USC Bunyadi.jpeg";

import UMTAdmissionsSpring from "../../images/AdsImages/UMT Admissions Spring.jpeg";

import DonateAkhwat from "../../images/AdsImages/Donate Akhuwat.jpeg";

import DailyAftabFounder from "../../images/AdsImages/Mumtaz Ahmed Tahir Founder Daily Aftab.jpeg";

import DailyAftabHeader from "../../images/AdsImages/Aftab Header.jpeg";

import PressInternationalDisclaimer from "../../images/AdsImages/Press International Disclaimer.png";

import ShaheedChoudharyNasarullahWarraich from "../../images/AdsImages/Shaheed Choudhary Nasarullah Warraich.jpeg";

import pinUrduWebP from "../../images/logos/webp/PINurdu.webp";

import SherazSheikhNews from "../../images/AdsImages/SherazSheikhNews.png";

import NooriPG1 from "../../images/AdsImages/NooriPG1.jpeg";
import NooriPG2 from "../../images/AdsImages/NooriPG2.jpeg";
import NooriPG3 from "../../images/AdsImages/NooriPG3.jpeg";
import NooriPG4 from "../../images/AdsImages/NooriPG4.jpeg";


function LeftSide() {
  return (
    <div className="classads">
      {/* <div className="advertisement">
        <picture>
          <source srcSet={AkramSheikhLahoreAVIF} type="image/avif" />
          <source srcSet={AkramSheikhLahoreWebP} type="image/webp" />
          <img
            className="Ads_ImageStyle"
            src={AkramSheikhLahore}
            alt="Akram Sheikh Lahore"
            loading="lazy"
          />
        </picture>
      </div> */}

      <div className="advertisement">
        <picture>
          {/* <source srcSet={AkramSheikhLahoreAVIF} type="image/avif" />
          <source srcSet={AkramSheikhLahoreWebP} type="image/webp" /> */}
          <img
            className="Ads_ImageStyle"
            src={NooriPG1}
            alt="Vote for Noori"
            loading="lazy"
          />
        </picture>
      </div>

      <div className="advertisement">
        <picture>
          {/* <source srcSet={AkramSheikhLahoreAVIF} type="image/avif" />
          <source srcSet={AkramSheikhLahoreWebP} type="image/webp" /> */}
          <img
            className="Ads_ImageStyle"
            src={NooriPG2}
            alt="Vote for Noori"
            loading="lazy"
          />
        </picture>
      </div>

      <div className="advertisement">
        <picture>
          {/* <source srcSet={AkramSheikhLahoreAVIF} type="image/avif" />
          <source srcSet={AkramSheikhLahoreWebP} type="image/webp" /> */}
          <img
            className="Ads_ImageStyle"
            src={NooriPG3}
            alt="Vote for Noori"
            loading="lazy"
          />
        </picture>
      </div>
      <div className="advertisement">
        <picture>
          {/* <source srcSet={AkramSheikhLahoreAVIF} type="image/avif" />
          <source srcSet={AkramSheikhLahoreWebP} type="image/webp" /> */}
          <img
            className="Ads_ImageStyle"
            src={NooriPG4}
            alt="Vote for Noori"
            loading="lazy"
          />
        </picture>
      </div>


      <div className="advertisement">
        <picture>
          {/* <source srcSet={AkramSheikhLahoreAVIF} type="image/avif" />
          <source srcSet={AkramSheikhLahoreWebP} type="image/webp" /> */}
          <img
            className="Ads_ImageStyle"
            src={ShaheedChoudharyNasarullahWarraich}
            alt="Shaheed Choudhary Nasarullah Warraich"
            loading="lazy"
          />
          <h3>A legend, the pride of Pakistan and a powerful voice of lawyers, Shaheed Chaudhary Nasarullah Warraich Senior Advocate of Supreme Court of Pakistan.</h3>
        </picture>
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={SherazSheikhNews}
          alt="ATCL Company Promotion"
          loading="lazy"
        />
      </div>

      {/* <div className="advertisement">
        <picture>
          <source srcSet={classadslogoAVIF} type="image/avif" />
          <source srcSet={classadslogoWebP} type="image/webp" />
          <img className="Ads_ImageStyle" src={classadslogo} alt="classadslogo" />
        </picture>
      </div> */}

      <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <source srcSet={ZiaLawAVIF} type="image/avif" />
          <source srcSet={ZiaLawWebP} type="image/webp" />
          <img
            className="Ads_ImageStyle"
            src={ZiaLaw}
            alt="Zia Law Associates card"
          />
        </picture>
      </div>

      <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <source srcSet={AAACounsaltantsAVIF} type="image/avif" />
          <source srcSet={AAACounsaltantsWebP} type="image/webp" />
          <img
            className="Ads_ImageStyle"
            src={AAACounsaltants}
            alt="AAA Consultants card"
          />
        </picture>
      </div>

      <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <source srcSet={FutureTechAVIF} type="image/avif" />
          <source srcSet={FutureTechWebP} type="image/webp" />
          <img
            className="Ads_ImageStyle"
            src={FutureTech}
            alt="Future Tech Systems card"
          />
        </picture>
      </div>

      <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <source srcSet={BalochAutosAVIF} type="image/avif" />
          <source srcSet={BalochAutosWebP} type="image/webp" />
          <img
            className="Ads_ImageStyle"
            src={BalochAutos}
            alt="Daily Adl card"
          />
        </picture>
      </div>


      {/* <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <img
            className="Ads_ImageStyle"
            src={DailyAftabFounder}
            alt="Mumtaz Ahmed Tahir Founder Daily Aftab"
          />
        </picture>
        <p>Mumtaz Ahmed Tahir Founder Daily Aftab</p>
      </div> */}



      {/* <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <img
            className="Ads_ImageStyle"
            src={AlkhidmatFoundationPakistan}
            alt="Alkhidmat Foundation Pakistan Ad"
          />
        </picture>
      </div> */}

      <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <img className="Ads_ImageStyle" src={BusyB} alt="Busy B Ad" />
        </picture>
      </div>

      {/* <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <img
            className="Ads_ImageStyle"
            src={GhurkiHospital}
            alt="Ghurki Hospital Ad"
          />
        </picture>
      </div> */}

    

      {/* <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <img
            className="Ads_ImageStyle"
            src={USCBunyadi}
            alt="USC Bunyadi Ad"
          />
        </picture>
      </div>

      <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <img
            className="Ads_ImageStyle"
            src={UMTAdmissionsSpring}
            alt="UMT Admissions Spring Ad"
          />
        </picture>
      </div> */}

      {/* <div className="advertisement">
        <p className="Ads_descriptionStyle"></p>
        <picture>
          <img
            className="Ads_ImageStyle"
            src={DonateAkhwat}
            alt="Donate Akhwat Ad"
          />
        </picture>
      </div> */}


      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={PressInternationalDisclaimer}
          alt="Press International advertisement disclaimer"
          loading="lazy"
        />
      </div>

      <h3
        style={{
          fontFamily: "Montserrat, sans-serif",
          color: "#333",
          padding: "20px",
          textAlign: "center",
          borderRadius: "12px",
          boxShadow: "0 8px 16px rgba(0,0,0,0.15)",
          textTransform: "uppercase",
          letterSpacing: "2px",
          background: "linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)",
          color: "white",
          textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        Advertise Here
      </h3>
    </div>
  );
}

export default LeftSide;
