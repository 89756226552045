import React from "react";
import { Link } from "react-router-dom";
import "./Error.css";

function ErrorPage() {
    return (
        <div className="error-page-container">
            <div className="error-page-content">
                <h1 className="error-page-title">404</h1>
                <h2 className="error-page-subtitle">Page Not Found</h2>
                <p className="error-page-description">
                    Oops! The page you are looking for does not exist. It might have been moved or deleted.
                </p>
                <Link to="/" className="error-page-home-link">Go to Home</Link>
            </div>
        </div>
    );
}

export default ErrorPage;
