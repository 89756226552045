// Video.jsx
import React from "react";
import AAAEdu from "./AAAConsultancy/AAAEdu.mp4";
import VideoComponent from "./VideoComponent"; 

function Video() {
  return (
    <div>
      <VideoComponent src={AAAEdu} />
    </div>
  );
}

export default Video;
