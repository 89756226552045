import React from 'react';
import Header from '../../../components/Header/Header.jsx';
import Navigation from '../../../components/Navigation/Navigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import About from '../../../components/About/About.jsx';

function AboutPage() {
    return(
        <div>
            <Header />
            <Navigation />
            <About />
            <Footer />

        </div>
    )
}

export default AboutPage;