import React from 'react';
import Header from '../../../components/Header/Header.jsx';
import Navigation from '../../../components/Navigation/Navigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import Video from '../../../components/Video/Video.jsx';

function VideoPage() {
  return (
    <div>
      <Header />
      <Navigation />
      <Video />
      <Footer />
    </div>
  );
}

export default VideoPage;
