import React from "react";
import "./Header.css"

import pinlogo from "../../images/logos/pinlogo.png";
import PINeng from "../../images/logos/PINeng.png";
import pinUrdu from "../../images/logos/PINurdu.png";

import pinlogoWebP from "../../images/logos/webp/pinlogo.webp";
import PINengWebP from "../../images/logos/webp/PINeng.webp";
import pinUrduWebP from "../../images/logos/webp/PINurdu.webp";

import pinlogoAVIF from "../../images/logos/avif/pinlogo.avif";
import PINengAVIF from "../../images/logos/avif/PINeng.avif";
import pinUrduAVIF from "../../images/logos/avif/PINurdu.avif";

function Header() {
  return (
    <div className="logo">
      <div>
        <picture>
          <source srcSet={pinlogoAVIF} type="image/avif" />
          <source srcSet={pinlogoWebP} type="image/webp" />
          <img className="imageLogo" src={pinlogo} alt="PIN Logo" />
        </picture>
      </div>
      <div>
        <picture>
          <source srcSet={PINengAVIF} type="image/avif" />
          <source srcSet={PINengWebP} type="image/webp" />
          <img className="imageLogo" src={PINeng} alt="English Logo" />
        </picture>
      </div>
      <div>
        <picture>
          <source srcSet={pinUrduAVIF} type="image/avif" />
          <source srcSet={pinUrduWebP} type="image/webp" />
          <img className="imageLogo" src={pinUrdu} alt="Urdu Logo" />
        </picture>
      </div>
    </div>
  );
}

export default Header;
