import React from "react";
import ATCLWebP from "../../images/AdsImages/webp/ATCL.webp";
import "./Sidebar.css";

function AccountancySidebar() {
  return (
    <div className="sidebar">
      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={ATCLWebP}
          alt="ATCL Company Promotion"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default AccountancySidebar;
