import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
// Import the toolbar plugin and styles
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

import Header from "../../../components/Header/Header.jsx";
import Navigation from "../../../components/Navigation/Navigation.jsx";
import Footer from "../../../components/Footer/Footer.jsx";
import SidebarHome from "../../../components/Sidebars/SidebarHome.jsx";
import ClassAds from "../../../components/ClassifiedADS/LeftSide.jsx";
import DailyAdl from "../../../data/news/DailyAdl.pdf";

import dailyadl from "../../../images/logos/dailyadl.png";
import dailyadlWebP from "../../../images/logos/webp/dailyadl.webp";
import dailyadlAVIF from "../../../images/logos/avif/dailyadl.avif";

function HomePageEnglish() {
  // Create an instance of the toolbar plugin with custom configuration
  const toolbarPluginInstance = toolbarPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {({ ZoomInButton, ZoomOutButton }) => {
          // Render only the ZoomIn and ZoomOut buttons
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ZoomOutButton />
              <ZoomInButton />
            </div>
          );
        }}
      </Toolbar>
    ),
  });

  const { Toolbar } = toolbarPluginInstance;

  return (
    <div>
      <div className="adlheader">
        <picture>
          <source srcSet={dailyadlAVIF} type="image/avif" />
          <source srcSet={dailyadlWebP} type="image/webp" />
          <img
            className="dailyadlheader"
            src={dailyadl}
            alt="Daily adl header"
            loading="lazy"
          />
        </picture>
      </div>

      <div className="container-collaboration">
        <div className="collaboration">In Collaboration With</div>
      </div>
      <Header />
      <Navigation />
      <div className="page-layout">
        <SidebarHome />
        <div
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          className="main"
        >
          {/* <Toolbar /> */}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer fileUrl={DailyAdl} plugins={[toolbarPluginInstance]} />
          </Worker>
        </div>

        <ClassAds />
      </div>
      <Footer />
    </div>
  );
}

export default HomePageEnglish;
