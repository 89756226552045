import React from "react";
import "./Footer.css";

function Footer(props) {
  const currentyear = new Date().getFullYear();

  return (
    <div>
      <footer className="footer">
        <p>&copy; Copyright {currentyear}<span className="footer_name"> Press International.</span> All Rights Reserved.</p>
      </footer>
    </div>




  );
}

export default Footer;
