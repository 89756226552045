import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import "./Navigation.css";

function Navigation() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <div className="navigation">
      <nav>
        <div className="container">
          <div className="menu-icon" onClick={handleShowNavbar}>
            <MenuIcon />
          </div>
          <div className={`nav-elements  ${showNavbar && "active"}`}>
            <ul>

              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <Link to="/news">News</Link>
              </li>

              {/* <li>
                <Link to="/londonroundup">London RoundUp</Link>
              </li> */}

              {/* <li>
                <Link to="/showbiz">ShowBiz</Link>
              </li> */}

              <li>
                <div class="dropdown">
                  <button class="dropbtn">Services</button>
                  <div class="dropdown-content">
                    <Link to="/law">Legal</Link>
                    <Link to="/accountancy">Accountancy</Link>
                    <Link to="/technology">Technology</Link>
                    <Link to="/education">Education</Link>
                  </div>
                </div>
              </li>

              <li>
                <div class="dropdown">
                  <button class="dropbtn">Personalities</button>
                  <div class="dropdown-content">
                    <Link to="/ukpersonality">UK</Link>
                    <Link to="/pkpersonality">Pakistan</Link>

                  </div>
                </div>
              </li>

              <li>
                <Link to="/artsandliterature">Arts and Literature</Link>
              </li>

              {/* <li>
                <Link to="/interviews">Interviews</Link>
              </li> */}

              <li>
                <Link to="/picturegallery">Picture Gallery</Link>
              </li>

              <li>
                <Link to="/videos">Videos</Link>
              </li>

              <li>
                <Link to="/contact">Contact</Link>
              </li>

              <li>
                <Link to="/about">About Us</Link>
              </li>

              {/* <li>
                <Link to="/urdu">Urdu</Link>
              </li> */}

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Navigation;
