import React from 'react';
import Header from '../../../components/Header/Header.jsx';
import Navigation from '../../../components/Navigation/Navigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import LawSidebar from '../../../components/Sidebars/LawSidebar.jsx';

function LawPage() {
  return (
    <div>
      <Header />
      <Navigation />
      <LawSidebar />
      <Footer />
    </div>
  );
}

export default LawPage;
