import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <div className="about_us">
        <h1>About Us</h1>

        <p>
          Press International Lahore Magazine was founded in 1997 by Nadeem
          Aslam Khawaja, along with Dr. Naveed Aslam Khawaja acting as the
          Co-founder and Publisher. Nadeem Aslam Khawaja holds an MA in Urdu and
          Mass Communication from Pakistan. He also holds a Postgraduate Diploma
          in Development Journalism from Punjab University, Lahore. Mr. Nadeem
          Aslam Khawaja has also pursued his studies at the Punjab University
          Law College, Lahore. Meanwhile he obtained admission to a Ph.D. in
          Mass Communication at the University of Education Lahore, under the
          supervision of Dr. Maskeen Ali Hijazi, the former chairman of the Mass
          Communication Department at Punjab University Lahore.
        </p>

        <p>
          Mr. Nadeem Khawaja has an extensive background in the education
          sector, having served as an Under Secretary at the Higher Education
          Department Civil Secretariat, Punjab, Lahore. Mr. Nadeem Khawaja
          joined the Department of Journalism at FC College, Lahore (a Chartered
          University), after denationalization, and supervised the Formanites
          Journalism Society as Chief Organizer. Under the guidance of Dr. Peter
          H. Armacost (Rector), he remodelled the department with his innovative
          ideas. He organized seminars, symposiums, and other activities to
          connect students with media professionals, fostering a bright future
          in the media industry.
        </p>
        <p>
          Dr. Naveed Aslam Khawaja, publisher & co-founder, earned an MBBS, MA
          in Urdu, MA in Mass Communication, LL. B., and a PGC in Nutrition,
          among other certificates in the medical field. Along with his medical
          career pursuits he also began his career as a sub-editor and later
          became a news editor in a daily newspaper. Due to some reasons, Mr
          Naveed Khawaja left the newspaper industry.
        </p>

        <p>
          After serving at FC College, Mr Nadeem Aslam Khawaja became the
          founding chairman of the Mass Communication Department at MAO College
          Lahore. He has imparted his extensive knowledge and expertise in this
          field by teaching Print Media at the Master's level at MAO College. He
          also initiated the teaching of MA in Mass Communication with the help
          and guidance of his Punjab University professors, Dr. Maskeen Ali
          Hijazi and Dr. Moghees ud Din Sheikh. Dr. Hijazi joined the visiting
          faculty. Additionally, Dr. Awais Saleem, Assistant Professor of Mass
          Communication at Lamar University USA, was part of the visiting
          faculty. Mr. Nadeem Khawaja innovatively ran the department by
          recruiting a young team of print and electronic media professionals
          and established a placement cell for outgoing students under the
          supervision of Zaheer Ahmad Babar, a renowned journalist.
        </p>

        <p>
          In addition to his academic pursuits, Mr. Nadeem Khawaja commenced his
          journalism career as a staff reporter in 1980 at the Daily Adl under
          the editorship of Jalal Rabbani. He then joined the Daily Imroz, a
          member of the National Press Trust of Pakistan, as a feature writer
          and feature editor. Furthermore, Mr. Nadeem Khawaja has extensive
          experience as a working journalist. He began his career as a reporter
          in a daily publication, later becoming a feature writer, and
          subsequently served as an editor at the Daily Rahbar. His career is a
          blend of working and academic experience.
        </p>

        <p>
          Following his achievements, Mr. Nadeem Khawaja moved abroad and
          settled in the UK. He served as an editor at a weekly newspaper but
          left due to differences in ideology. Subsequently, he established his
          own newspaper, Bilingual Press International, which was already being
          published from Lahore. The advisory board of Press International
          comprised Muzamal Hassan, former GM of PTV; Dr. Akhtar Shumaar, a
          renowned columnist and former Chairman of the Department of Urdu at FC
          College University, Lahore; Dr. Awais Saleem, who previously worked as
          a staff reporter at Daily News, Lahore, and currently serves as an
          Assistant Professor at the Mass Communication Department, Lamar
          University, USA; and Zaheer Ahmed Babar, a senior journalist in print
          and electronic media.
        </p>

        <p>
          It’s unfair not to mention about Mr. Nadeem Aslam Khawaja's partner
          who played a vital role in publishing Press International from London.
        </p>

        <p>
          We are pleased to announce our return to the forefront of digital and
          print media, embracing contemporary advancements to meet the evolving
          needs of our audience. Our team is committed to delivering insightful
          and innovative content, and we trust that you will recognize and value
          the progressive efforts we are making to serve the public effectively.
          Your continued support and engagement are vital to our mission of
          providing quality journalism and fostering informed communities.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or inquiries, feel free to reach out to us.
          We'd love to hear from you!
        </p>
        <p>Email: pressglobal24@gmail.com</p>
      </div>
    </div>
  );
}

export default About;
