import React from "react";
import PersonalityPicture from "./PersonalityPicture.jsx";
import Details from "./Details.jsx";
import {Link} from "react-router-dom";

function PersonalityCard(props) {
  return (
    <div>
      <div className="card">
        <div className="top">
          <PersonalityPicture img={props.img} />
          <h2 className="name">{props.name}</h2>
        </div>
        <div className="bottom">
          <Details detailInfo={props.profession} />
          <Link to={props.path} className="button">Read More</Link>
        </div>
      </div>
    </div>
  );
}

export default PersonalityCard;
