import React from "react";
import ATCLWebP from "../../images/AdsImages/webp/ATCL.webp";
import LawfareSolicitorsWebP from "../../images/AdsImages/webp/Lawfaresolictitors.webp";
import MariaConsultancyWebP from "../../images/AdsImages/webp/MariaConsultancyLondon.webp";
import PressInternationalLogoWebP from "../../images/logos/webp/pinlogo.webp";
import DailyAdlWebP from "../../images/AdsImages/webp/DailyAdl.webp";
import JalalRabbaniWebP from "../../images/IndividualPersons/webp/JalalRabbani.webp";

import AkramSheikhIslamabadAVIF from "../../images/AdsImages/avif/AkramSheikhIslamabad.avif";
import AkramSheikhIslamabadWebP from "../../images/AdsImages/webp/AkramSheikhIslamabad.webp";
import AkramSheikhIslamabad from "../../images/AdsImages/AkramSheikhIslamabad.png";

import Haaya from "../../images/AdsImages/Haaya.jpeg";

import KalamKahaniKhawajaNadeem from "../../images/AdsImages/Kalam Kahani Khawaja Nadeem.jpg";

import MenaraLounge from "../../images/AdsImages/Menara Lounge.jpeg";

import SayNoToSingleUsePlastics from "../../images/AdsImages/SayNoToSingleUsePlastics.jpeg";


import PressInternationalDisclaimer from "../../images/AdsImages/Press International Disclaimer.png";

import WASALahore from "../../images/AdsImages/WASA lahore.jpeg";

import TaxExcellence2024 from "../../images/AdsImages/Tax Excellence Rewards 2024.jpeg";

import DuaForPalestine from "../../images/AdsImages/Dua for Palestine.jpeg";

import pinUrduWebP from "../../images/logos/webp/PINurdu.webp";

import Hum28MayWalayHai from "../../images/AdsImages/Hum28MayWalayHai.jpeg";



import "./Sidebar.css";

function SidebarHome() {
  return (
    <div className="sidebar">
      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={AkramSheikhIslamabadWebP}
          alt="Akram Sheikh Islamabad"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={ATCLWebP}
          alt="ATCL Company Promotion"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={LawfareSolicitorsWebP}
          alt="Lawfare solicitors Company Promotion"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={MariaConsultancyWebP}
          alt="Maria Consultancy Promotion"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={Haaya}
          alt="Haaya Hiring"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={JalalRabbaniWebP}
          alt="Jalal Rabbani - Founder Of Daily ADL"
          loading="lazy"
        />
        <p className="Ads_descriptionStyle">
          Jalal Rabbani - Founder Daily ADL
        </p>
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={KalamKahaniKhawajaNadeem}
          alt="Kalam Kahani Writtem By Khawaja Nadeem Aslam Ad"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={Hum28MayWalayHai}
          alt="Hum 28 May Walay Hai Pakistan Ad"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={MenaraLounge}
          alt="Menara Lounge Ad"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={SayNoToSingleUsePlastics}
          alt="Say No to Single Use Plastics Ad"
          loading="lazy"
        />
      </div>

      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={WASALahore}
          alt="Wasa Lahore Ad"
          loading="lazy"
        />
      </div>



      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={PressInternationalDisclaimer}
          alt="Press International advertisement disclaimer"
          loading="lazy"
        />
      </div>

      <h3
        style={{
          fontFamily: "Montserrat, sans-serif",
          color: "#333",
          padding: "20px",
          textAlign: "center",
          borderRadius: "12px",
          boxShadow: "0 8px 16px rgba(0,0,0,0.15)",
          textTransform: "uppercase",
          letterSpacing: "2px",
          background: "linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)",
          color: "white",
          textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        Advertise Here
      </h3>

      {/* 
      <div className="advertisement">
        <img
          className="Ads_ImageStyle"
          src={DuaForPalestine}
          alt="Dua For Palestine"
          loading="lazy"
        />
      </div> */}


    </div>
  );
}

export default SidebarHome;
