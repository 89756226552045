import React from "react";
import PersonalityCard from "./PerosnalityCard.jsx";
import PKpersonality from "../../data/PKpersonality.js";

import "./personality.css";

function createCard(contact) {
  return (
    <PersonalityCard
      key={contact.id}
      name={contact.name}
      img={contact.imgURL}
      profession={contact.profession}
      path={contact.path}
      
    />
  );
}

function PKPersonality() {
  return (
    <div className="personality">
      <div className="PKpersonality">
        <h1 className="heading">Pakistan</h1>
        {PKpersonality.map(createCard)}
      </div>
    </div>
  );
}

export default PKPersonality;
