// VideoComponent.jsx
import React, { Suspense } from 'react';
import "./Video.css";

const VideoContent = React.lazy(() => import('./VideoContent'));

function VideoComponent({ src }) {
  return (
    <div>
      <Suspense fallback={<div>Loading Video...</div>}>
        <VideoContent src={src} />
      </Suspense>
    </div>
  );
}

export default VideoComponent;
