import React from "react";
import Header from "../../../components/Header/Header.jsx";
import Navigation from "../../../components/Navigation/Navigation.jsx";
import Footer from "../../../components/Footer/Footer.jsx";
import "./Akram Sheikh.css";

function AkramSheikh() {
  return (
    <div>
      <Header />
      <Navigation />

      <div className="akram-container">
        <h1 className="title">Akram Sheikh</h1>

        <div className="content">
          <p><strong>Place of Birth:</strong> Vehari District, Punjab, Pakistan</p>

          <h2>Education</h2>
          <ul>
            <li>Bar-At-Law</li>
            <li>LL.B, University of Punjab</li>
            <li>B.A. (English Literature, Economics) Forman Christian College</li>
          </ul>

          <h2>Career at the Bar</h2>
          <ul>
            <li>Enrolled as an Advocate - 1973</li>
            <li>Enrolled as an Advocate of the Supreme Court - 1981</li>
            <li>Bestowed upon the title of Senior Advocate by the Supreme Court - 1994</li>
          </ul>

          <h2>Posts Held</h2>
          <ul>
            <li>Elected Member Punjab Bar Council - 1978-90</li>
            <li>Vice Chairman (Elected Chief) Punjab Bar Council - 1985</li>
            <li>President Lahore High Court Bar Association - 1988-89</li>
            <li>Elected Member Pakistan Bar Council - 1990-1995</li>
            <li>Ambassador-at-Large with the status of Federal Minister - 1993</li>
            <li>Chairman National Hijra Council with the status of Full Federal Minister - 1993</li>
            <li>Founder President Supreme Court Bar Association of Pakistan - 1995-1997</li>
            <li>Pakistan’s Roving Ambassador with the status of Full Federal Minister - 1997</li>
            <li>Head of Pakistan’s Delegation to the United Nation’s 49th Sub commission on “Prevention of Discrimination & Protection of Minorities” at Geneva - 1997</li>
            <li>Country President Union Internationale des Avocats - 1998</li>
            <li>Elected Member Pakistan Bar Council - 2000-2005</li>
            <li>Elected Council Member Commonwealth Lawyers’ Association - 2008-2011</li>
          </ul>

          <h2>Memberships</h2>
          <ul>
            <li>Founder member Supreme Court Bar Association of Pakistan (SCBAP)</li>
            <li>SAARCLAW (Founding Member October 1991)</li>
            <li>American Bar Association (ABA)</li>
            <li>Union International des Avocates (UIA) Since 1990</li>
            <li>Commonwealth Lawyers Association (CLA) Since 1990</li>
            <li>The Law Association for Asia and the Pacific (LAWASIA) Since 1985</li>
            <li>Canadian Bar Association (1995-2000)</li>
            <li>International Bar Association (IBA) Until 2000</li>
            <li>Honourable Society of Middle Temple Inn</li>
          </ul>

          <h2>Principal Areas of Practice</h2>
          <ul>
            <li>Human Rights</li>
            <li>Corporate & Regulatory Law</li>
            <li>Constitutional Law</li>
            <li>Public Interest Litigation</li>
            <li>Intellectual Property</li>
            <li>Administrative Law</li>
            <li>Banking & Finance</li>
            <li>White Collar Crimes</li>
            <li>Commercial Litigation</li>
            <li>Civil & Criminal</li>
            <li>Acquisitions & Mergers</li>
            <li>Media & Entertainment</li>
            <li>Project Development & Finance</li>
            <li>Public Policy</li>
            <li>Telecommunications</li>
            <li>Competition Laws</li>
            <li>Tax</li>
            <li>Private International Law</li>
            <li>Election Laws</li>
            <li>Information Technology</li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AkramSheikh;
