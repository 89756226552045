import React from 'react';
import Header from '../../../components/Header/Header.jsx';
import Navigation from '../../../components/Navigation/Navigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import Contact from '../../../components/Contact/Contact.jsx';

function Contactpage() {
  return (
    <div>
      <Header />
      <Navigation />
      <Contact />
      <Footer />
    </div>
  );
}

export default Contactpage;
