import React from "react";
import Header from "../../../components/Header/Header.jsx";
import Navigation from "../../../components/Navigation/Navigation.jsx";
import Footer from "../../../components/Footer/Footer.jsx";
import DailyAdl from "../../../data/news/DailyAdl.pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core";
// Import the toolbar plugin and styles
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

function NewsPage() {

    // Create an instance of the toolbar plugin with custom configuration
    const toolbarPluginInstance = toolbarPlugin({
      renderToolbar: (Toolbar) => (
        <Toolbar>
          {({ ZoomInButton, ZoomOutButton }) => {
            // Render only the ZoomIn and ZoomOut buttons
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <ZoomOutButton />
                <ZoomInButton />
              </div>
            );
          }}
        </Toolbar>
      ),
    });
  
    const { Toolbar } = toolbarPluginInstance;

  return (
    <div>
      <Header />
      <Navigation />
      <div className="news">
      <div
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          className="newsPaper"
        >
          {/* <Toolbar /> */}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer fileUrl={DailyAdl} plugins={[toolbarPluginInstance]} />
          </Worker>
        </div>


      </div>

      <Footer />
    </div>
  );
}

export default NewsPage;
