import SherazShiekh from "../images/IndividualPersons/SherazShiekh.jpeg";
import ArifRehman from "../images/IndividualPersons/ArifRehman.jpeg";
import AsimSalman from "../images/IndividualPersons/AsimSalman.jpeg";
import RashadAslam from "../images/IndividualPersons/RashadAslam.jpeg";
import AsifAkram from "../images/IndividualPersons/AsifAkram.jpeg";

const UKpersonality = [
  {
    id: 1,
    name: "Arif Rehman",
    imgURL: ArifRehman,
    profession: "Barrister",
  },
  {
    id: 2,
    name: "Asim Salman",
    imgURL: AsimSalman,
    profession: "Solicitor",
  },

  {
    id: 3,
    name: "Rashad Aslam",
    imgURL: RashadAslam,
    profession: "Barrister",
  },

  {
    id: 4,
    name: "Sheraz Sheikh",
    imgURL: SherazShiekh,
    profession: "Accountant & Tax Advisor",
  },

  {
    id: 5,
    name: "Asif Akram",
    imgURL: AsifAkram ,

    profession: "Banker and Economist.",

  },
];

export default UKpersonality;
