import React from 'react';
import Header from '../../../components/Header/Header.jsx';
import Navigation from '../../../components/Navigation/Navigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';

import BismillahCovers from "../../../images/BookCovers/Bismillah.jpeg";
import MilnaCharDhiharay from "../../../images/BookCovers/MilnaCharDhiharay.jpeg";
import Walaysrahwir from "../../../images/BookCovers/Walaysrahwir.jpeg";

import Ajizana from "../../../images/BookCovers/Ajizana.jpeg";
import AnthakabJamal from "../../../images/BookCovers/AnthakabJamal.jpeg"
import BhartiHari from "../../../images/BookCovers/BhartiHari.jpeg"

import HumainTere from "../../../images/BookCovers/HumainTere.jpeg"
import HumRandha from "../../../images/BookCovers/HumRandha.jpeg"
import LahoreKi from "../../../images/BookCovers/LahoreKi.jpeg"

import MienBhi from "../../../images/BookCovers/MienBhi.jpeg"
import RoshiniPhol from "../../../images/BookCovers/RoshiniPhol.jpeg"

// import  from "../../../images/BookCovers/.jpeg"
// import  from "../../../images/BookCovers/.jpeg"
// import  from "../../../images/BookCovers/.jpeg"

function Artsandliterature() {
    return(
        <div>
            <Header />
            <Navigation />

            <div className="bookcovers">
            <img src={BismillahCovers} alt="Bishmillah Book Cover" />
            <img src={MilnaCharDhiharay} alt="MilnaCharDhiharay Book Cover" />
            <img src={Walaysrahwir} alt="Walaysrahwir Book Cover" />

            <img src={Ajizana} alt="Ajizana Book Cover" />
            <img src={AnthakabJamal} alt="AnthakabJamal Book Cover" />
            <img src={LahoreKi} alt="LahoreKi Book Cover" />

            <img src={BhartiHari} alt="BhartiHari Book Cover" />
            <img src={HumainTere} alt="HumainTere Book Cover" />
            <img src={HumRandha} alt="HumRandha Book Cover" />

            <img src="" alt="" />
            <img src="" alt="" />
            <img src="" alt="" />

            <img src="" alt="" />
            <img src="" alt="" />
            <img src="" alt="" />
          </div>

            <Footer />

        </div>
    )
}

export default Artsandliterature;