import React from 'react';
import Header from '../../../components/Header/Header.jsx';
import Navigation from '../../../components/Navigation/Navigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import PKPersonality from '../../../components/Peronality/PKPersonality.jsx';


function PKPersonalityPage() {
    return(
        <div>
            <Header />
            <Navigation />
            <PKPersonality />
            <Footer />

        </div>
    )
}

export default PKPersonalityPage;