import AkramSheikhWithNKhawaja from "./PicturegalleryImages/AkramSheikhWithNKhawaja.jpeg";
import AkramSheikhWithZKhawaja from "./PicturegalleryImages/AkramSheikhWithZKhawaja.jpeg";
import NasarullahWarrich from "./PicturegalleryImages/A legend & great lawyer Shaheed Choudhary Nasarullah Warraich with former Chief Justice of Pakistan Iftikhar Choudhary.jpeg";
import DailyAftabFounder from "../../../../images/AdsImages/Mumtaz Ahmed Tahir Founder Daily Aftab.jpeg";
import JalalRabbaniWebP from "../../../../images/IndividualPersons/webp/JalalRabbani.webp";
import TahirNasarullahWarraich from "../../../../images/IndividualPersons/TahirNasarullahWarraich.jpeg";
import AsifAkram from "../../../../images/IndividualPersons/AsifAkram.jpeg";
import SherazSheikh1 from "../../../../images/IndividualPersons/SherazSheikh1.jpeg";
import ShaheedChoudharyNasarullahWarraich from "../../../../images/AdsImages/Shaheed Choudhary Nasarullah Warraich.jpeg";



const PictureGalleryData = [
    { id: 1, src: ShaheedChoudharyNasarullahWarraich , alt: "Shaheed Choudhary Nasarullah Warraich.", description: "A legend, the pride of Pakistan and a powerful voice of lawyers, Shaheed Chaudhary Nasarullah Warraich Senior Advocate of Supreme Court of Pakistan."},
    { id: 2, src: AkramSheikhWithNKhawaja, alt: 'Akram Sheikh with N Khawaja', description: "London: Chief Editor Press International Nadeem Khawaja interviewing Mr.Akram Sheikh former President Supreme Court Bar Association of Pakistan." },
    { id: 3, src: AkramSheikhWithZKhawaja, alt: 'Akram Sheikh with Z Khawaja', description: "London: Managing Editor Press International Z.Khawaja with Akram Sheikh, former President Supreme Court Bar Association of Pakistan."},
    { id: 4, src: NasarullahWarrich , alt: " A legend, the pride of Pakistan, and a powerful voice of lawyers, Shaheed Chaudhary Nasarullah Warraich. ", description: " A legend, the pride of Pakistan and a powerful voice of lawyers, Shaheed Chaudhary Nasarullah Warraich Senior Advocate of Supreme Court of Pakistan with former Chief Justice of Pakistan, Iftikhar Chaudhry."},
    { id: 5, src: DailyAftabFounder , alt: " Mumtaz Ahmed Tahir Founder Daily Aftab", description: "Mumtaz Ahmed Tahir Founder Daily Aftab."},
    { id: 6, src: JalalRabbaniWebP , alt: "Jalal Rabbani - Founder Daily ADL", description: "Jalal Rabbani - Founder Daily ADL."},
    { id: 7, src: TahirNasarullahWarraich , alt: "Tahir Nasarullah Warraich, Senior Advocate of Supreme Court of Pakistan & Former President of Lahore High Court Bar Association", description: "Tahir Nasarullah Warraich, Advocate of Supreme Court of Pakistan & Former President of Lahore High Court Bar Association."},
    { id: 8, src: AsifAkram , alt: "Asif Akram, A Banker & Economist.", description: "Asif Akram, Banker & Economist."},
    { id: 9, src: SherazSheikh1 , alt: "Sheraz Sheikh Tax Consultant.", description: "Sheraz Sheikh - Tax Consultant."},

];

export default PictureGalleryData;
