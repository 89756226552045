import React from "react";
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./Contact.css";
import { Link } from "react-router-dom";


function Contact() {
  return (
    <div>
      <div className="contact">
        <h1>Contact Us</h1>
        <p>
          Feel free to reach out to us for any inquiries or questions you may
          have. We would be happy to assist you.
        </p>

        <h2>Email</h2>
        <p>Email: pressgbobal24@gmail.com</p>

        <h2>UK</h2>
        <p>23A Kenilworth Gardens, Hayes, Middlesex, UB4 0AY, London</p>

        <h2>Pakistan</h2>
        <p>Ali Jaan Plaza, Rehman Garden, Lahore</p>

      </div>

      <div className="advertisements-contact">
        <h2>Please Contact for Advertisements</h2>
        <p>For advertising inquiries or opportunities, please get in touch with our:</p>
        <h3>Editor (Business)</h3>
        <p>Zunair Nadeem Khawaja</p>
        <p>pressnetworkads@gmail.com</p>
        <h3></h3>
      </div>

      <div className="social-media">
        <h3>Connect with Us:</h3>
        <ul>
          <li>
            <a href="https://www.youtube.com/channel/UChKrOWIhPuEHUX2sE8ouUIg" target="_blank" rel="noreferrer">
              <YouTubeIcon style={{ fontSize: "5em" }}  />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/pressglobal24/" target="_blank" rel="noreferrer">
              <InstagramIcon style={{ fontSize: "5em" }} />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/pressglobal245" target="_blank" rel="noreferrer">
              <TwitterIcon style={{ fontSize: "5em" }} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;
