import React from "react";

import Error from "../../../components/Error/Error.jsx";

function ErrorPage() {
  return (
    <Error />
   
  )
}

export default ErrorPage;
