import React from 'react';
import Header from '../../../components/Header/Header.jsx';
import Navigation from '../../../components/Navigation/Navigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';

function TechnologyPage() {
    return(
        <div>
            <Header />
            <Navigation />
            <Footer />

        </div>
    )
}

export default TechnologyPage;