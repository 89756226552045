import React from "react";
import Header from "../../../components/Header/Header.jsx";
import Navigation from "../../../components/Navigation/Navigation.jsx";
import Footer from "../../../components/Footer/Footer.jsx";
import PKPersonality from "../../../components/Peronality/PKPersonality.jsx";
import "./Basharat Ali Gill.css";

function BasharatAliGill() {
  return (
    <div>
      <Header />
      <Navigation />

      <div className="basharat-container">
        <h1 className="title">Basharat Ali Gill</h1>

        <p className="content">
          Mr. Basharat Ali Gill, born on 2nd March, 1972, in Toba Tek Singh,
          comes from an educated and respected family. His father, Dr. Fazal
          Hakeem Gill, a graduate of the College of Veterinary Sciences, Lahore
          and Oxford University, joined the Indian British Army as a
          commissioned officer in 1945. He was a well-decorated, renowned
          military officer who received several medals for gallantry and
          trophies in equestrianism.
        </p>

        <p className="content">
          Mr. Basharat Ali Gill is a law graduate from Punjab University Law
          College, Lahore. He completed his internships in criminal trials at
          Faisal Najeeb Law Associates, assisting his seniors in a number of
          high-profile murder trials. Later, he started his practice in the
          District Courts of Toba Tek Singh and was elected as an office bearer
          of the District Bar Association, Toba Tek Singh.
        </p>

        <p className="content">
          In 2003, he joined the well-known law firm of Mr. Ch. Muhammad
          Nasrullah Warraich, ASC, former Chairman of the Pakistan Bar Council.
          In 2004, Mr. Basharat Ali Gill was appointed as Deputy District Public
          Prosecutor in Lahore, where he conducted hundreds of criminal trials
          related to murder, narcotics, drugs, etc., and undertook appellate
          work extensively before the High Court and the Federal Shariat Court
          until 2007.
        </p>

        <p className="content">
          He handled hundreds of civil cases, appeals, civil revisions before
          the Honorable High Court, and assisted his senior in civil appellate
          work of the High Court and Supreme Court of Pakistan. In 2022, Mr.
          Basharat Ali Gill was appointed as Assistant Advocate General of
          Punjab, representing the Government of Punjab before the Lahore High
          Court at the principal seat and Multan Bench. Subsequently, the
          Government of Punjab promoted him to Additional Advocate General of
          Punjab. Currently, Mr. Basharat Ali Gill is serving as the Deputy
          Attorney General for Pakistan, representing the Federal Government of
          Pakistan in higher courts in Lahore. A well-known lawyer in Lahore
          with 22 years of experience at the bar, he is ambitious for the rule
          of law and true democracy in Pakistan.
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default BasharatAliGill;
