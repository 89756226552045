import AkramShiekh from "../images/IndividualPersons/AkramShiekh.jpeg";
import TahirNasarullahWarraich from "../images/IndividualPersons/TahirNasarullahWarraich.jpeg";
import BasharatGill from "../images/IndividualPersons/BasharatGill.jpeg";
import BasharatAliGill from "../components/Peronality/PKPersonalityPages/Basharat Ali Gill.jsx";
import AsifAkram from "../images/IndividualPersons/AsifAkram.jpeg";

const PKpersonality = [
    {
      id: 1,
      name: "Akram Sheikh",
      imgURL: AkramShiekh ,

      profession: "Senior Advocate Supreme Court of Pakistan. Former President Supreme Court Bar Association.",

      path: "/pkpersonality/akramsheikh", 


    },

    {
      id: 2,
      name: "Basharat Ali Gill",
      imgURL: BasharatGill ,

      profession: "Deputy Attorney General, Pakistan. Former Additional Advocate General, Punjab.",

      path: "/pkpersonality/basharataligill", 

    },

    {
      id: 3,
      name: "Tahir Nasarullah Warraich",
      imgURL: TahirNasarullahWarraich ,

      profession: "Advocate Supreme Court of Pakistan. Former President Lahore High Court Bar Association.",

    },




    
  
  ];
  
  export default PKpersonality;
  