import React from "react";
import Header from "../../../../components/Header/Header.jsx";
import Navigation from "../../../../components/Navigation/Navigation.jsx";
import Footer from "../../../../components/Footer/Footer.jsx";

import "./PictureGallery.css";
import PictureGalleryData from "./PictureGalleryData.js"; // Ensure this path matches your data file

function PictureGallery() {
  // Function to prevent default actions like dragging and right-click
  const preventDefaultActions = (e) => {
    e.preventDefault();
  };

  return (
    <div className="pg-wrapper">
      <Header />
      <Navigation />
      <div className="pg-container">
        <div className="pg-gallery">
          {PictureGalleryData.map((image) => (
            <div
              key={image.id}
              className="pg-gallery-item"
              onContextMenu={preventDefaultActions}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="pg-gallery-image"
                draggable="false"
                onDragStart={preventDefaultActions}
              />
              <div className="pg-image-description">{image.description}</div>{" "}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PictureGallery;
